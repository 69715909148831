<ng-template #onlineindicator>
    <div class="online_status">
      Online
    </div>
  </ng-template>
  <ng-template #offlineindicator>
    <div class="offline_status">
      Offline
    </div>
  </ng-template>


  <ng-template #blockloader>
    <div class="loader_body">
        <div class="loader-inner-body">
            <i class="pi pi-spin pi-spinner"></i>  Loading...
        </div>
    </div>
  </ng-template>


  

  <ng-template #userprofilepicture let-profile>

        <p-avatar
            [label]="commonservice.acronym(profile.user_data.first_name+' '+profile.user_data.last_name)"
            styleClass="p-mr-2"
            size="small"
            shape="circle"
            *ngIf="!profile.user_data.userphotofile"
            [style]="{'background-color': 'rgb(88 114 152)', 'color': '#ffffff'}"
        ></p-avatar>
        <p-avatar
            styleClass="p-mr-2"
            size="small"
            shape="circle"
            *ngIf="profile.user_data.userphotofile"
            [image]="profile.user_data.userphotofile"
            [style]="{'background-color': 'rgb(88 114 152)', 'color': '#ffffff'}"
        ></p-avatar>

        
        <span class="min-online-status" [ngClass]="{'offline': !isOnline(profile.user_data.id)}"></span>

  </ng-template>



<div class="chat-main-container">
    <!-- <div class="chat-container-header">
        <h2 class="pull-left">iMessage</h2>
    
        <button pButton pRipple type="button" (click)="openNewMessage()" icon="fa fa-pencil-square-o" class="pull-right p-button-rounded p-button-text"></button>
    </div> -->
    
    <div class="chat-user-container">
        <div class="chat-top-container text-center">


            <button (click)="back()" pButton pRipple pTooltip="Back" type="button" (click)="openNewMessage()" icon="fa fa-arrow-left" class="pull-left p-button-rounded p-button-text"></button>
            <span *ngIf="commonservice.is_mobile" class="chat-title">iChat</span>
            <button pButton pRipple pTooltip="Sync Message" type="button" (click)="sync()" icon="fa fa-refresh" class="pull-right p-button-rounded p-button-text"></button>
            <button pButton pRipple pTooltip="Create Message" type="button" (click)="openNewMessage()" icon="fa fa-pencil-square-o" class="pull-right p-button-rounded p-button-text"></button>
        </div>

        <div class="chat-search-container">
            <span class="p-input-icon-left chat-search">
                <i class="pi pi-search" *ngIf="!search"></i>
                <i class="pi pi-arrow-left pointer" (click)="search=''" *ngIf="search"></i>
                <input type="text" pInputText [(ngModel)]="search" class="chat-user-search" placeholder="Search">         
            </span>
        </div>


        <div class="chat-body-summary-list">

            <ng-template *ngIf="chat_summary_loader" [ngTemplateOutlet]="blockloader">
            </ng-template>

            <ng-container  *ngFor="let s of chat_summary"  >
                <div *ngIf="!chat_summary_loader" class="chat-summary-item" (click)="openChatSession(s)" [ngClass]="{'chat_active': chat_session.id==s.to}">
                    <div class="chat-summary-inner" [ngClass]="{'unread': !s.read}">
                        
                        <div class="chat-summary-profile-pic">



<ng-template *ngTemplateOutlet="userprofilepicture; context: {$implicit: {user_data: commonservice.userProfileData(s.to)}}" >
</ng-template>

                            <!-- <img [src]="commonservice.userProfilePicture(s.to)"> -->
                        </div>
                        
                        <div class="chat-summary-label">
                            <div class="chat-summary-user-name">
                                {{commonservice.userName(s.to)}}
                            </div>
                            <div class="chat-summary-message">
                                <span *ngIf="s.origin==1">You: </span> {{s.message}}
                            </div>
                            <div class="chat-summary-date">
                                {{momentDate(s.created_date)}}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>



        <div class="chat-foot-container">
            Current online: {{online_users.length}}
        </div>

    </div>


    <div class="chat-msg-container" [hidden]="commonservice.is_mobile && !open_chat">

        <button *ngIf="open_chat" [hidden]="!commonservice.is_mobile" pButton pRipple pTooltip="Back" type="button" (click)="cancelChat()" icon="fa fa-arrow-left" class="pull-left p-button-rounded p-button-text mobile-bck-btn "></button>
   

        <div class="chat-head" *ngIf="chat_session">
            
      
            <div class="chat-head-inner">
                        
                <div class="chat-head-profile-pic">
                    
<ng-template *ngTemplateOutlet="userprofilepicture; context: {$implicit: {user_data: commonservice.userProfileData(chat_session.id)}}" >
</ng-template>

                    <!-- <img [src]="commonservice.userProfilePicture(chat_session.id)"> -->
                </div>
                


                <div class="chat-head-label">
                    <div class="chat-head-user-name">
                        {{commonservice.userName(chat_session.id)}}
                    </div>
                    <!-- <ng-container *ngIf="isOnline(chat_session.id); then onlineindicator; else offlineindicator"></ng-container> -->
                    
                </div>
            </div>
        </div>  



        <div class="chat-head" *ngIf="!chat_session">
            
                <span class="pull-left chat-font chat-new-to">To: </span>

                <p-multiSelect
                    #chatnewopen
                    placeholder="Search Name, Email, Company, Department or Group" 
                    [options]="options"
                    [filter]="true"
                    [showToggleAll]="false"
                    [(ngModel)]="selected"
                    [resetFilterOnHide]="true"
                    selectedItemsLabel="ellipsis"
                    optionGroupLabel="label"
                    optionLabel="filter"
                    [autofocusFilter]="true"
                    optionGroupChildren="child"
                    [group]="true"
                    styleClass="chat-search-user w-100 pull-left"
                    [virtualScroll]="true" 
                    (onChange)="changeValue($event)"
                    [itemSize]="10"
                >


                <!-- (onFilter)="onFilterTextChanged($event.filter)"
                (onFocus)="setUserOption()" -->

                <ng-template pTemplate="input">
                <input type="text" pInputText class="inputfield w-full" value="test">
                </ng-template>

                <ng-template let-users pTemplate="selectedItems" >


                    <div *ngIf="selected.length > 0" class="chat-font">
                        <ng-container *ngFor="let u of selected; let i = index" >
                            <div class="chat-selected-user">
                                <div class="standard-user-dp pull-left ">
 
                                    <ng-template *ngTemplateOutlet="userprofilepicture; context: {$implicit: {user_data: commonservice.userProfileData(u.id)}}" >
                                    </ng-template>
        
                                </div>
                                <span class="pull-left chat-search-name">{{commonservice.userName(u.id)}}</span>
                                <i class="pi pi-times" (click)="removeThis(i)"></i>
                            </div>
                        </ng-container>
                    </div>
                    
                    <div *ngIf="selected.length <= 0" class="chat-font chat-new-placeholder">
                        Search Name, Email, Company, Department or Group
                    </div>



                </ng-template>
                <ng-template let-user pTemplate="item">
                    <div class="standard-user-item">
                    <div class="standard-user-dp">
                        <ng-template *ngTemplateOutlet="userprofilepicture; context: {$implicit: {user_data: commonservice.userProfileData(user.id)}}" >
                        </ng-template>
                   
                    </div>
                    <div class="chat-search-item-name">{{user.full_name}}</div>
                    <small class="chat-search-item-email">({{user.email}})</small>
                    </div>
                </ng-template>
                <ng-template let-user pTemplate="empty">
                <span *ngIf="!user_no_result">
                    <i class="fa fa-spinner fa-spin"></i> Loading...
                </span>
                <span *ngIf="user_no_result">
                    No Result Found.
                </span>
                
                </ng-template>
                </p-multiSelect>


            <!-- <button (click)="back()" pButton pRipple pTooltip="Back" type="button" icon="pi pi-times" class="chat-font pull-right p-2 mt-2 p-button-rounded p-button-text"></button> -->
            <button (click)="back()" pButton pRipple pTooltip="Chat Info" type="button" icon="pi pi-info-circle" class="chat-font pull-right p-2 mt-2 p-button-rounded p-button-text"></button>
        

        </div>


        <div class="chat-body" #chatbody>


                                
            <ng-template *ngIf="chat_loader" [ngTemplateOutlet]="blockloader">
            </ng-template>



            <div class="chat-body-inner">


                

    
    
                <ng-container *ngIf="!chat_loader">
    
                    <div *ngFor="let c of chat_messages;" class="chat-content" [ngClass]="{'owner': config.userid==c.from}">
                        <div class="standard-user-dp">
                            <ng-template *ngTemplateOutlet="userprofilepicture; context: {$implicit: {user_data: commonservice.userProfileData(c.from)}}" >
                            </ng-template>
                        </div>
                        <div class="chat-inner">
                            <div class="chat-name"><span>{{commonservice.userName(c.from)}}</span>  <div class="chat-date"> {{ momentDate(c.created_date) }}</div> <br></div>
                            <br>
                            <pre>{{c.message}}</pre>
                              <!-- {{setCommentDate(c.created_date)}} -->
                        </div>
                    </div>
                </ng-container>
            </div>


        </div>
    
        
        <div class="chat-bottom" [ngClass]="{'d-none': selected.length <= 0 && !chat_session}">
            <div class="chat-msg-text" 
            #chatMessage
            contenteditable 
            (keydown)="onEnter($event)"
            (input)="message = chatMessage.innerText"
            ></div>

            <button (click)="send()" [disabled]="!message || (selected.length<=0 && !chat_session)"  pButton pRipple pTooltip="Send" type="button" icon="pi pi-send" class="chat-font pull-right p-2 mt-2 p-button-rounded p-button-text"></button>
     

        </div>
    
    </div>

</div>