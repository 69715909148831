<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-overlayPanel #opUserAssignment appendTo="body" [showCloseIcon]="true" [dismissable]="false" [style]="{width: '450px'}">
    <ng-template pTemplate>
        <p-table [value]="availableUser" [rowHover]="true" [loading]="loading" scrollHeight="flex" class="i95-table"
            [autoLayout]="true" [resizableColumns]="true" [style]="{height: '310px'}" [scrollable]="true"
            styleClass="p-datatable-responsive-demo mb-6" 
            dataKey="id" [paginator]="true" [rows]="200" responsiveLayout="scroll"
            [(selection)]="selectedUsers">
            <ng-template pTemplate="header">
                <tr >
                    <th class="text-center table-chckbox" >
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th style="width:150px;" class="p-5" pSortableColumn="full_name">Employee name
                        <p-sortIcon field="full_name"></p-sortIcon>
                    </th>
                </tr>
                <tr *ngIf="availableUser.length <= 0">
                    <th colspan="2" class="text-center">
                        No data available
                    </th>
                </tr>
                <tr >
                    <th class="table-chckbox"></th>
                    <th style="width:150px;">
                        <p-columnFilter field="full_name" matchMode="contains" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <input type="text" pInputText [ngModel]="value" (ngModelChange)="filter($event)"
                                    class="p-inputtext" placeholder="Search Here">
                            </ng-template>
                        </p-columnFilter>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user>
                <tr height="40">
                    <td class="text-center table-chckbox">
                        <p-tableCheckbox [value]="user"></p-tableCheckbox>
                    </td>
                    <td  style="width:150px">{{user.full_name | titlecase}} </td>
                </tr>
            </ng-template>
        </p-table>
        <button pButton pRipple type="button" label="Save" class="p-button-raised w-full mt-3" (click)="saveAssignedUsers(opUserAssignment)"></button>
    </ng-template>

</p-overlayPanel>


<div class="p-d-flex mb-2">
    <div class="grid">
        <div class="col-6">
            <b>List of Assigned User</b>
        </div>
        <div class="col-6">
            <button pButton pRipple type="button" icon="pi pi-refresh" pTooltip="Refresh" tooltipPosition="bottom"
                (click)="getAssignUsers()" class="p-button-outlined p-button-secondary pull-right">
            </button>
            <button pButton pRipple type="button" icon="pi pi-check-square" label="Assign User"
                pTooltip="Select users to be assign to this project." tooltipPosition="bottom"
                class="p-button-outlined p-button-info pull-right m-3 mb-0 mt-0"
                (click)="opUserAssignment.toggle($event);">
                
            </button>
        </div>
    </div>
</div>
<p-table [value]="assignedUsers" responsiveLayout="scroll" [rows]="10" [rowHover]="true" [loading]="loading" #usertable class="i95-table"
    [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,50, 100]"
    scrollHeight="flex" [resizableColumns]="true"
    [style]="{height: table_height+'px'}" [scrollable]="true" styleClass="p-datatable-responsive-demo"  [totalRecords]="totalRecords"
    dataKey="id">
    <!-- <ng-template pTemplate="caption">
        <div class="table-header">
            
        </div>
    </ng-template> -->
    <ng-template pTemplate="header">
        <tr *ngIf="assignedUsers.length <= 0">
            <th colspan="2" class="text-center">
                No records found!
            </th>
        </tr>
        <tr *ngIf="assignedUsers.length > 0">
            <th class="text-center table-chckbox" *ngIf="assignedUsers.length > 0">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th style="width:150px" pSortableColumn="full_name"> Fullname
                <p-sortIcon field="full_name"></p-sortIcon>
            </th>
           
            <th style="width:200px" pSortableColumn="email"> Email
                <p-sortIcon field="email"></p-sortIcon>
            </th>

            <th style="width:100px" *ngFor="let access of useraccess" class="text-center">
                {{access.action+ " Access" | titlecase}}
            </th>
            <th class="text-center small-width"> </th>
        </tr>
        <tr *ngIf="assignedUsers.length > 0">
            <th class="table-chckbox"></th>
            <th style="width:150px">
                <p-columnFilter field="full_name" matchMode="contains" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <input type="text" pInputText [ngModel]="value" (ngModelChange)="filter($event)"
                            class="p-inputtext" placeholder="Search Here" >
                    </ng-template>
                </p-columnFilter>
            </th>
           
            <th style="width:200px">
                <p-columnFilter field="email" matchMode="contains" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <input type="text" pInputText [ngModel]="value" (ngModelChange)="filter($event)" class="p-inputtext"
                            placeholder="Search Here">
                    </ng-template>
                </p-columnFilter>
            </th>
            <th style="width:150px" *ngFor="let access of useraccess" >
                
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
        <tr>
            <td class="text-center table-chckbox">
                <p-tableCheckbox [value]="user"></p-tableCheckbox>
            </td>
            <td style="width:150px">{{user.full_name | titlecase}} </td>
            <td style="width:200px">{{user.email}} </td>
            <td style="width:100px;" *ngFor="let access of useraccess;let i = index">
                <!-- <p-inputSwitch class="pl-2 pr-2" (onChange)="accessChanged($event,user)" [checked]="user['access-'+user.id][i][access.action]"></p-inputSwitch> -->
                <!-- <input type="checkbox" [checked]="user['access-'+user.id][i][access.action]" (change)="accessChanged($event,user,access['action'])"> -->
                <p-checkbox name="access" value="{{access['action']}}" [(ngModel)]="user.access" (onChange)="accessChanged($event,user)"></p-checkbox>
                <!-- [(ngModel)]="user['access-'+user.id][i][access.action]" -->
            </td>
            <td style="width:small-width">
                <button type="button" icon="pi pi-trash" (click)="removeAssignedUser(user)"
                    class="p-button-danger p-ripple p-button p-component p-button-icon-only pull-right">
                    <i class="pi pi-trash"></i>
                </button>
            </td>
        </tr>
    </ng-template>
</p-table>