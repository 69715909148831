<div class="col-sm-5 mt-3   pull-left shiptable-div p-0"  [ngClass]="{'w-100': commonservice.is_mobile}">
  

    <span class="p-input-icon-left w-100 mb-3" *ngIf="commonservice.is_mobile">
        <i class="pi pi-search"></i>
        <input type="text" pInputText placeholder="Search" class="w-100" [(ngModel)]="search_value" />
    </span>

    <p-table
        [value]="commonservice.data.projects"
        [paginator]="(!commonservice.is_mobile)" 
        [rows]="50" 
        [rowHover]="true"
        [responsive]="true"
        responsiveLayout="stack"
        [scrollable]="(!commonservice.is_mobile)"
        scrollHeight="flex"
        [style]="style"
        [autoLayout]="true"
        styleClass="p-datatable-responsive-demo p-0 border"
        [(selection)]="selected_data_add"
    >
        <ng-template pTemplate="header">
            <tr>
                <th class="table-chckbox">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>Project Name</th>
                
                <th class="small-width text-center p-fc-right">Add</th>
            </tr>

            <tr >
                <th class="table-chckbox">
                    
                </th>
                <th class="">
                    <p-columnFilter type="text" field="project_name"  [showMenu]="false"></p-columnFilter>
                </th>
                <th class="small-width text-center p-fc-right">
                  
                </th>
            </tr>

        </ng-template>
        <ng-template pTemplate="body" let-project>

            <ng-container *ngIf="!commonservice.is_mobile">
                <tr [hidden]="checkAssigned(project.id)">
                    <td class="table-chckbox">
                        <p-tableCheckbox [value]="project"></p-tableCheckbox>
                    </td>

                    <td>{{project.project_name}}</td>
                    <td  class="small-width text-center p-fc-right">
                        <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-button-sm" (click)="assignProject(project.id)" class="p-button-rounded p-button"></button>
                    </td>
                </tr>
            </ng-container>

            <ng-container *ngIf="commonservice.is_mobile">
                <tr >
                    <td >
                        <div class="item-list-mobile-container">
                             {{project.project_name}}

                            <span>
                                <p-inputSwitch
                                    [ngModel]="checkAssigned(project.id)"               
                                    (ngModelChange)="assignedSwitch(project, $event)"
                                ></p-inputSwitch>
                            </span>
                        </div>
                    </td>
                </tr>
            </ng-container>

        </ng-template>
    </p-table>
</div>

<div class="col-sm-2 pull-left mid-btn-container shiptable-div" *ngIf="!commonservice.is_mobile">

    <button [disabled]="!selected_data_add || !selected_data_add.length" pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded" (click)="assignProject(selected_data_add, false)"></button>
    <button [disabled]="!selected_data_del || !selected_data_del.length" pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger" (click)="removeAssigned(selected_data_del, false)"></button>

</div>


<div class="col-sm-5 mt-3 pull-left shiptable-div" *ngIf="!commonservice.is_mobile">
    <p-table
        [value]="setAssigned()"
        [paginator]="true" 
        [rows]="50" 
        [rowHover]="true"
        [responsive]="true"
        [scrollable]="true" 
        scrollHeight="flex"
        [style]="{height: '400px'}"
        [autoLayout]="true"
        [(selection)]="selected_data_del"
        styleClass="p-datatable-responsive-demo p-0 border"
    >
        <ng-template pTemplate="header">
            <tr>
                <th class="table-chckbox">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>Project Name</th>
                <th class="small-width text-center p-fc-right">Remove</th>
            </tr>

            <tr >
                <th class="table-chckbox">
                    
                </th>
                <th class="">
                    <p-columnFilter type="text" field="project_name"  [showMenu]="false"></p-columnFilter>
                </th>
                <th class="small-width text-center p-fc-right">
                  
                </th>
            </tr>

        </ng-template>
        <ng-template pTemplate="body" let-project>
            <tr>
                <td class="table-chckbox">
                    <p-tableCheckbox [value]="project"></p-tableCheckbox>
                </td>

                <td>{{project.project_name}}</td>
                <td  class="small-width text-center p-fc-right">
                    <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-sm" (click)="removeAssigned(project.id)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>