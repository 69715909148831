import { Component, OnInit, Input, EventEmitter, Output, ViewChild, SimpleChanges } from '@angular/core';
import { FormWizardService } from 'src/app/services/general/form-wizard.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';

import moment from 'moment-timezone';
import { AzureMultipleSelectionComponent } from '../../../feature/azure-multiple-selection/azure-multiple-selection.component';
import { AzureInputSearchComponent } from '../../../feature/azure-input-search/azure-input-search.component';
import { UserDropdownWizardComponent } from '../user-dropdown-wizard/user-dropdown-wizard.component';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { NgClass, NgIf, NgFor, NgSwitch, NgSwitchCase } from '@angular/common';
import { UserService } from 'src/app/services/user/user.service';
import { CommonService } from 'src/app/services/common/common.service';


@Component({
    selector: 'app-form-wizard',
    templateUrl: './form-wizard.component.html',
    styleUrls: ['./form-wizard.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        NgIf,
        NgFor,
        NgSwitch,
        NgSwitchCase,
        InputTextModule,
        CalendarModule,
        InputTextareaModule,
        DropdownModule,
        MultiSelectModule,
        InputSwitchModule,
        ToggleButtonModule,
        UserDropdownWizardComponent,
        AzureInputSearchComponent,
        AzureMultipleSelectionComponent,
    ],
})
export class FormWizardComponent implements OnInit {

  constructor(
    public service: FormWizardService, 
    public formBuilder: UntypedFormBuilder,
    private userService: UserService,
    public commonService: CommonService
    ) {
      

   }

  formFields: any;
  formGroup!: UntypedFormGroup;
  @Input() componentData: any;

  @Output() outputData = new EventEmitter<any>();
  // @Input() formValue: any;
  @Input() get formValue(): any { return this.getValue(); }
  @Output() dropdownChangeEvent = new EventEmitter<any>();
  // @Output() public onFormGroupChange = new EventEmitter<any>();
  status: any = [];
  departments: any = [];
  locations: any = [];
  shipyards: any = [];
  ships: any = [];
  portfolios: any = [];
  other_data: any = {};
  users: any = [];
  dropdownValue: any = [];
  append_to: string = 'body';
  show_errors: boolean = false;
  // moduleForm: FormGroup;

  resolveRef: any;
  rejectRef: any;

  _response:any;
  _err:any;

  //create a new promise. Save the resolve and reject reference

  set formValue(form_value: any) {


    // fix date issue 
    for(let f in form_value){
      if(this.date_fields.includes(f)){
        if(form_value[f]=='0000-00-00' || !form_value[f]){
          delete form_value[f];
        }else{
          form_value[f] = new Date(form_value[f]);
        }
      }
    }

    this.setValue(form_value);
  }


  setValue(val_data:any=null){
    var ob = Object.keys(val_data).length === 0;

    if(this.formGroup!=undefined){
      this.formGroup.reset();
    }
    if(!ob){

      for(let v in val_data){
        var item = this.formGroup.get(v);
        // console.log(item)
        if(item){
          item.setValue(val_data[v]);
        }else{
          this.other_data[v] = val_data[v];
        }
      }
    }

    
  }

  setErrors(val_data:any={}){

    var ob = Object.keys(val_data).length === 0;
    
    if(!ob){
    
      for(let v in val_data){
        var item = this.formGroup.get(v);
        // console.log(item)
        if(item){
          item.setValue(val_data[v]);
        }
      }
    }

  }

  getValue(){
    return this.formGroup.value;
  }


  ngOnInit(): void {
    
    this.formGroup = this.formBuilder.group({});

    this.status = [
      {
        "name": "Open",
        "code": "open"
      },
      {
        "name": "Started",
        "code": "started"
      },
      {
        "name": "Not Started",
        "code": "not started"
      },
      {
        "name": "Completed",
        "code": "completed"
      },
      {
        "name": "Cancelled",
        "code": "cancelled"
      }];

    this.portfolios = [
      {
        "name": "AEP",
        "code": 'AEP'
      },
      {
        "name": "Landbase",
        "code": "Landbase"
      },
      {
        "name": "Newbuild",
        "code": "Newbuild"
      },
      {
        "name": "Revitalization",
        "code": "Revitalization"
      },
      {
        "name": "Technical",
        "code": "Technical"
      },
      {
        "name": "Operations",
        "code": "Operations"
      },
      {
        "name": "Team View",
        "code": "Team View"
      },
      {
        "name": "Design View",
        "code": "Design View"
      }];

    this.shipyards = [
      {
        "name": "NA",
        "code": 1
      },
      {
        "name": "PAP - Papenburg",
        "code": 2
      },
      {
        "name": "STN - Saint-Nazaire",
        "code": 3
      },
      {
        "name": "TUR - Turku",
        "code": 4
      }];
  
    setTimeout(() => {
      this.ships = this.dropdownFormatter(this.commonService.data.ships, 'name', 'id');
      this.departments = this.dropdownFormatter(this.commonService.data.departments, 'department', 'id');
      this.locations = this.dropdownFormatter(this.commonService.data.locations, 'location_name', 'id');
      // this.users = this.dropdownFormatter(this.commonService.data.users, 'full_name', 'id');
    }, 500)
 
    this.formFields = this.componentData.fields;

    if(this.componentData.noappend){
      this.append_to = '';
    }

    this.getDateFields();
    this.createFormControl();    

  }

  date_fields:any = [];
  getDateFields(){ 
    this.formFields.forEach((element: { Id: string; }) => {
      
      var new_element: any = element; 

      // call company list
      if(new_element.Type=='date'){
        this.date_fields.push(new_element.Id);
      }
    });
    // console.log(this.date_fields);
  }


  createFormControl() {
    

    this.service.setField(this.formFields);
    
    this.formFields.forEach((element: { Id: string; }) => {
      
      var new_element: any = element; 

      // call company list
      if(new_element.Type=='company'){
        this.commonService.loadCommon('company');
      }
      var req: boolean = (new_element.Required) ? new_element.Required : false; 
      
      this.formGroup.addControl(element.Id, new UntypedFormControl(''));
    });

    this.formGroup.valueChanges.subscribe((formValues) => {
      // console.log('input ',formValues);

      this.service.setData(formValues);
      // Object.assign(formValues, this.other_data);
      
      Object.keys(formValues).forEach(key => {
        // this.answers[key] = values[key];
        if(formValues[key]==null){
          formValues[key] = '';
        }
        
        if(this.date_fields.includes(key)){
          if(formValues[key]!=''){
            formValues[key] = moment(formValues[key]).format('Y-MM-DD');
          }
        }
      });


      // console.log('output',formValues);
      this.outputData.emit(formValues);
    });

  }

  async save() {
    let dataPromise: Promise<void> = new Promise((resolve, reject) => {
      this.resolveRef = resolve;
      this.rejectRef = reject;
    });
    let id = this.componentData.id;
    let url = this.componentData.api_url;
    // this.componentData.data = this.formGroup.value;

    this.service.saveForm(this.formGroup.value, id, url).subscribe(
      res => {
        let response: any = res;
        this.resolveRef(response);
      },
      err => {
        let error: any = err;
        this.rejectRef(error);
      }
    );

    return await dataPromise;

  }


  //on other component call  let value:any = await this.formWizard.getDataById();
  //will return the all the value of the formwizard - userd in edit function
  async getDataById() {
    let id = this.componentData.id;
    let url = this.componentData.api_url;

    let dataPromise: Promise<void> = new Promise((_response, _err) => {
      this._response = _response;
      this._err = _err;
    });

    this.service.getDataById(id, url).subscribe(
      res => {
        let response: any = res;
        let fields: any = {};
        Object.keys(this.formGroup.value).forEach((name) => {
          // if(name != "departments"){
          this.getFieldType(name, (fieldType: any) => {
            switch (fieldType) {
              case 'date':
                fields[name] = new Date(response[name]);
                break;
              case 'select':
                if (!isNaN(response[name])) fields[name] = Number(response[name]);
                else fields[name] = response[name];
                break;
              // case 'switch':
                // if (response[name] == 1 || response[name] == true || response[name] == 'true' ) fields[name] =true;
                // else fields[name] = false;
                // break;
              default:
                fields[name] = response[name];
                break;
            }
          });
        });

        this.formGroup.patchValue(fields);
        this._response(this.formGroup.value);
      },
      err => {
        this._err(null);
      }
    );

    return dataPromise;
  }

  getFieldType(fieldId: string, callback: any) {
    this.formFields.forEach((element: any) => {
      if (fieldId == element['Id']) {
        callback(element['Type']);
      }
    });
  }

  async delete() {
    let dataPromise: Promise<void> = new Promise((resolve, reject) => {
      this.resolveRef = resolve;
      this.rejectRef = reject;
    });

    let id = this.componentData.id;
    let url = this.componentData.api_url;
    if (this.componentData.id > 0) {
      this.service.deleteData(id, url).subscribe(
        res => {
          let response: any = res;
          this.resolveRef(response);
        },
        err => {
          let error: any = err;
          this.rejectRef(error);
        }
      );
      return await dataPromise;
    }
  }

  getDropdown(dropdown: string) {
    //get the reference needed on this module
    switch (dropdown) {
      case 'ships':
        return this.ships;

      case 'status':
        return this.status;

      case 'shipyards':
        return this.shipyards;

      case 'locations':
        return this.locations;

      case 'departments':
        return this.departments;

      // case 'users':
      //   return this.users;

      case 'portfolios':
        return this.portfolios;
    }
  }

  dropdownFormatter(data: [], _keyName: string, _keyId: string) {
    this.dropdownValue = [];

    if(!data) return [];

    data.forEach((element: any) => {
      this.dropdownValue.push({ 'name': element[_keyName], 'code': element[_keyId] });
    });

    return this.dropdownValue;
  }

  userOnChange(){
    
  }

  dropdownOnChange(){
    let _val:any = this.formGroup.value;
    this.dropdownChangeEvent.emit(_val);
  }

  

}


