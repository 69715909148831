import { Component, OnInit, Input } from '@angular/core';
import { ProjectService } from 'src/app/services/general/project.service';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { CommonService } from 'src/app/services/common/common.service';






@Component({
    selector: 'app-projectassign',
    templateUrl: './projectassign.component.html',
    styleUrls: ['./projectassign.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, FormsModule, InputTextModule, TableModule, SharedModule, ButtonModule, RippleModule, InputSwitchModule]
})
export class ProjectassignComponent implements OnInit {
  @Input()
  get userid(): string { return this.user_id; }
  user_id: any;
  search_value: any = '';
  selected_data_add: any = [];
  selected_data_del: any = [];
  
  style: any = {};


  constructor(
    public commonservice: CommonService,
    public projectservice: ProjectService,
    ) { }

    ngOnInit(): void {
      this.commonservice.loadCommon('projects');

      if(this.commonservice.is_mobile){
        this.style = {height: 'calc(100vh - 250px)', 'overflow-y': 'auto'};
      }else{
        this.style = {height: '400px'};
      }
  
    }
  
      // check if the ship is already assigned
  checkAssigned(id:any = 0){
    return this.projectservice.assigned_project.includes(id);
  }

  
  assignedSwitch(p:any, e:any){

    if(e==true) {
      this.assignProject(p.id)
    }else{
      this.removeAssigned(p.id);
    }

  }

  // remove assigned ships
  removeAssigned(data:any = 0, single:boolean = true){

    if(single){
      var project_index = this.projectservice.assigned_project.indexOf(data);
      this.projectservice.assigned_project.splice(project_index, 1);
    }else{
      for(let proj of data){
        var project_index = this.projectservice.assigned_project.indexOf(proj.id);
        this.projectservice.assigned_project.splice(project_index, 1);
      }
    }
  }

  assignProject(data: any = '', single:boolean = true){
    if(single){
      this.projectservice.assigned_project.push(data);
    }else{
      for(let project of data){
        this.projectservice.assigned_project.push(project.id);
      }
    }
  }

    setAssigned(){
      var data: any = [];
      for(let p of this.commonservice.data.projects) {
        if(this.projectservice.assigned_project.includes(p.id)){
          data.push(p);
        }
      }
      return data;
    }


}
