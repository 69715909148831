<div class="row">


    <div class=" col-lg-5 p-0 i95-table" [ngClass]="{'w-100 mt-2 pr-2 pl-2': commonservice.is_mobile, 'border mt-4': !commonservice.is_mobile}">
    
        <span class="p-input-icon-left w-100 mb-3" *ngIf="commonservice.is_mobile">
            <i class="pi pi-search"></i>
            <input type="text" pInputText placeholder="Search" class="w-100" [(ngModel)]="search_value" />
        </span>
   

    
        <p-table
            [value]="commonservice.data.modules"
            sortMode="single"
            [autoLayout]="true"
            [rowHover]="true"
            [responsive]="true"
            [scrollable]="(!commonservice.is_mobile)"
            scrollHeight="flex"
            [style]="style"
            [autoLayout]="true"
            styleClass="p-datatable-responsive-demo p-0"
            groupRowsBy="module.origin_id"
            dataKey="module.id"
        >   
        
        
        <ng-template pTemplate="caption" *ngIf="!commonservice.is_mobile">
            <div class="table-header">
                Available Modules                
            </div>
            </ng-template>
            <ng-template pTemplate="header">

                <tr>
    
                    <th class="table-chckbox">
                        <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                    </th>
                    <th>Module</th>
                        <th class="small-width text-center" >
                            <span class="w-100">ADD</span>
                        </th>
                </tr>
    
    
                <tr>
                    <th class="table-chckbox">
    
                    </th>
                    <th>
                        <p-columnFilter type="text" field="name" [showMenu]="false"></p-columnFilter>
                    </th>
                    <th class="small-width">
    
                    </th>
                </tr>
    
            </ng-template>



            

            <ng-template pTemplate="body" let-module let-rowIndex="rowIndex" let-expanded="expanded" let-rowspan="rowspan" let-rowgroup="rowgroup">
                
                
                
                    <ng-container *ngIf="!commonservice.is_mobile">
                        <ng-container  *ngIf="module.parent">
                            <tr  *ngIf="accessValue2Parent(module.origin_id)">
                                <td colspan="3">
                                    <button type="button" pButton pRipple [pRowToggler]="module" class="p-button-text p-button-rounded p-button-plain mr-2" [icon]="!isExpand(module.origin_id) ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" (click)="Expand(module.origin_id)"></button>
                                    <span class="p-text-bold p-ml-2">
                                        <b>
                                            {{module.name}}
                                        </b>
                                    </span>
                                </td>
                                <td class="small-width text-center" >
                                    <span class="w-100">
                                        <button type="button" (click)="assignAll(module.origin_id)" pButton pRipple  [pRowToggler]="module" class="p-button-rounded p-button-secondary" pTooltip="ADD ALL" icon="pi pi-plus"></button>
                                    </span>
                                </td>
                            </tr>
                        </ng-container>

                        <ng-container *ngIf="!module.parent">
                            
                                <tr *ngIf="!accessValue2(module.id) && !isExpand(module.origin_id)">

                                    <td class="table-chckbox">
                                        <!-- <p-checkbox [ngModel]="accessValue('all',module)" (click)="checkAccess('all',module)" [binary]="true"></p-checkbox> -->
                                    </td>

                                    <td style="padding: 15px !important;">
                                        {{module.name}}
                                    </td>
                                    <td class="small-width text-center" >

                                        <span class="w-100">
                                            <button type="button" pButton pRipple [pRowToggler]="module" class="p-button-rounded p-button-secondary" pTooltip="ADD" icon="pi pi-plus" (click)="addModule(module)"></button>
                                        </span>
                                    </td>
                                </tr>
                                
                            </ng-container>
                    </ng-container>


                    
                    <ng-container *ngIf="commonservice.is_mobile">
                        <ng-container  *ngIf="module.parent">
                            <tr>
                                <td  >
                                    <div class="item-list-mobile-container">
                                       
                                        <button type="button"  pButton pRipple [pRowToggler]="module" class="p-button-text p-button-rounded p-button-plain mr-2" [icon]="!isExpand(module.origin_id) ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" (click)="Expand(module.origin_id)"></button>
                                        
                                        <span class="p-text-bold p-ml-2 mt-2 pull-left">
                                              <b>{{module.name}}</b>  
                                        </span>
    
                                        <span class="input-switch">
                                            <p-inputSwitch
                                                [ngModel]="!accessValue2Parent(module.origin_id)"              
                                                (ngModelChange)="assignedSwitchParent(module, $event)"
                                            ></p-inputSwitch>
                                        </span>
                                    </div>
                                </td>

                            </tr>

                        </ng-container>

                        <ng-container *ngIf="!module.parent">
                            <tr  *ngIf="!isExpand(module.origin_id)">
                                <td  >
                                    <div class="item-list-mobile-container">
                                       
                                        <span style="padding-left: 50px !important;">
                                            {{module.name}}
                                        </span>
    
                                        <span class="input-switch">
                                            <p-inputSwitch
                                                [ngModel]="accessValue2(module.id)"              
                                                (ngModelChange)="assignedSwitch(module, $event)"
                                            ></p-inputSwitch>
                                        </span>
                                    </div>
                                </td>

                            </tr>


                        </ng-container>
                    </ng-container>
    
            </ng-template>
        </p-table>
    </div>
    
    
    <div class="col-lg-1" *ngIf="!commonservice.is_mobile">
    
    </div>
    
    <div class="border col-lg-6 mt-4 p-0" *ngIf="!commonservice.is_mobile">
        <p-table
        [value]="commonservice.data.modules"
        sortMode="single"
        [autoLayout]="true"
        [rowHover]="true"
        [responsive]="true"
        [scrollable]="true"
        scrollHeight="flex"
        [style]="{height: '400px'}"
        [autoLayout]="true"
        styleClass="p-datatable-responsive-demo p-0"
        groupRowsBy="module.origin_id"
        dataKey="module.id"
        >
        <ng-template pTemplate="caption">
            <div class="table-header">
                Assigned Modules                
            </div>
        </ng-template>
            <ng-template pTemplate="header">
    
                <tr>
    
                    <th class="table-chckbox">
                        <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                    </th>
                    <th>Module</th>
                        <th class="small-width text-center" >
                            <span class="w-100">ADD</span>
                        </th>
                </tr>
    
    
                <tr>
                    <th class="table-chckbox">
    
                    </th>
                    <th>
                        <p-columnFilter type="text" field="name" [showMenu]="false"></p-columnFilter>
                    </th>
                    <th class="small-width">
    
                    </th>
                </tr>
    
            </ng-template>



            

            <ng-template pTemplate="body" let-module let-rowIndex="rowIndex" let-expanded="expanded" let-rowspan="rowspan" let-rowgroup="rowgroup">
                <ng-container  *ngIf="module.parent">
                    <tr  *ngIf="accessValue2Parent2(module.origin_id)">
                        <td colspan="3">
                            <button type="button" pButton pRipple [pRowToggler]="module" class="p-button-text p-button-rounded p-button-plain mr-2"  [icon]="!isExpand(module.origin_id) ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" (click)="Expand(module.origin_id)"></button>
                            <span class="p-text-bold p-ml-2">
                                <b>
                                    {{module.name}}
                                </b>
                            </span>
                        </td>
                        <td class="small-width text-center" >
                            <span class="w-100">
                                <button type="button" (click)="removeAll(module.origin_id)"  pButton pRipple  [pRowToggler]="module" class="p-button-rounded p-button-danger" pTooltip="REMOVE ALL" icon="pi pi-times"></button>
                            </span>
                        </td>
                    </tr>
                </ng-container>

                <ng-container *ngIf="!module.parent">
                    
                        <tr *ngIf="accessValue2(module.id) && !isExpand(module.origin_id)">

                            <td class="table-chckbox">
                                <!-- <p-checkbox [ngModel]="accessValue('all',module)" (click)="checkAccess('all',module)" [binary]="true"></p-checkbox> -->
                            </td>

                            <td style="padding: 15px !important;">
                                {{module.name}}
                            </td>
                            <td class="small-width text-center" >

                                <span class="w-100">
                                    <button type="button" pButton pRipple [pRowToggler]="module" class="p-button-rounded p-button-danger" pTooltip="REMOVE" icon="pi pi-times" (click)="removeModule(module)"></button>
                                </span>
                            </td>

                        </tr>
                </ng-container>
    
            </ng-template>
        </p-table>
    </div>
    
</div>