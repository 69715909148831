import { Component, OnInit } from '@angular/core';
import { ModuleService } from 'src/app/services/general/module.service';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { CommonService } from 'src/app/services/common/common.service';
import { GlobalService } from 'src/app/services/global/global.service';




@Component({
    selector: 'app-assignmodule',
    templateUrl: './assignmodule.component.html',
    styleUrls: ['./assignmodule.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, FormsModule, InputTextModule, TableModule, SharedModule, ButtonModule, RippleModule, TooltipModule, InputSwitchModule]
})
export class AssignmoduleComponent implements OnInit {
  checked_data: any = [];
  checked: boolean = true;
  search_value: any = '';
  style: any = {};

  constructor(
    public commonservice: CommonService,
    public globalservice: GlobalService,
    public moduleservice: ModuleService
    ) { }

  ngOnInit(): void {
    this.commonservice.loadCommon('modules');
    // console.log(this.commonservice.data.modules);

    if(this.commonservice.is_mobile){
      this.style = {height: 'calc(100vh - 250px)', 'overflow-y': 'auto'};
    }else{
      this.style = {height: '400px'};
    }
  }


  assignedSwitchParent(m:any, e:any){
    if(e==true) {
     this.assignAll(m.origin_id)
    }else{
      this.removeAll(m.origin_id)
    }

  }
  assignedSwitch(m:any, e:any){
    if(e==true) {
      this.addModule(m)
    }else{
      this.removeModule(m);
    }

  }
  checkAccess(access:any='', module: any){

    var data:any = this.commonservice.inData(this.moduleservice.assigned_module, 'module_id',module.id);

    // 
    if(access=='all'){

      if(data){
        if(((data['read']) && data['write'] && data['delete'])){
          data['read'] = 0;
          data['write'] = 0;
          data['delete'] = 0;
        }else{
          data['read'] = 1;
          data['write'] = 1;
          data['delete'] = 1;
        }
      }else{
        var new_data: any = {}; 
        new_data['module_id'] = module.id;
        new_data['read'] = 1;
        new_data['write'] = 1;
        new_data['delete'] = 1;
        this.moduleservice.assigned_module.push(new_data);
      }


    }else{
      if(data){
        if(data[access]){
          data[access] = 0;
        }else{
          data[access] = 1;
        }
      }else{
        var new_data: any = {}; 
        new_data['module_id'] = module.id;
        new_data[access] = 1;
        this.moduleservice.assigned_module.push(new_data);
      }

    }
    
  


    return;
  }


  addModule(m:any) {
    // console.log(m);
    // console.log(this.moduleservice.assigned_module);
    this.moduleservice.assigned_module.push(m.id);
    
  }
  

  removeModule(m:any) {
    // console.log(m);
    // console.log(this.moduleservice.assigned_module);
    let mindex:any = this.moduleservice.assigned_module.indexOf(m.id);
    if(mindex>=0){
      this.moduleservice.assigned_module.splice(mindex,1);
    }
    
  }

  in_collapse:any = [];
  Expand(m_id:any=0){
    let mindex:any = this.in_collapse.indexOf(m_id);
      if(mindex>=0){
        this.in_collapse.splice(mindex,1);
      }else{
        this.in_collapse.push(m_id);
      }

  }
  isExpand(m_id:any = 0){
    return this.in_collapse.includes(m_id);

  }

  isCollapse(module:any=0){
    let modules:any = this.globalservice.getMultiData(this.commonservice.data.modules,'origin_id', module.origin_id);

    console.log(modules);
  }

  assignAll(module_id:any=0){
    let modules:any = this.globalservice.getMultiData(this.commonservice.data.modules,'origin_id', module_id);
    for(let m of modules) {
      // console.log(m);
      if(!m.parent){
        if(!this.accessValue2(m.id)){
          this.moduleservice.assigned_module.push(m.id);
        }
      }
    }
  }
  removeAll(module_id:any=0){
    let modules:any = this.globalservice.getMultiData(this.commonservice.data.modules,'origin_id', module_id);
    for(let m of modules) {
      let mindex:any = this.moduleservice.assigned_module.indexOf(m.id);
      if(mindex>=0){
        this.moduleservice.assigned_module.splice(mindex,1);
      }
    }
  }
  


  accessValue(access:any='', module: any) {
    
    var data:any = this.commonservice.inData(this.moduleservice.assigned_module, 'module_id',module.id);
    
    if(data){
      if(access === 'all'){
        return ((data['read']) && data['write'] && data['delete']) ? true : false;
      }else{
        return (data[access]) ? data[access] : false;
      }

    }
    return;
  }
  


  accessValue2Parent2(module_id: any = 0) {
    // return this.moduleservice.assigned_module.includes(module_id);
      let parent_module:any = this.globalservice.getMultiData(this.commonservice.data.modules,'origin_id', module_id);
      // console.log('parent',parent_module);
      let ret = false;
      if(parent_module.length > 0){
        for(let p of parent_module){
            if(p.parent==false){
              // console.log('child',p);
              if(this.moduleservice.assigned_module.includes(p.id)){
                ret = true;
              }
            }
        }

      }

      return ret;

  }

  accessValue2Parent(module_id: any = 0) {
    // return this.moduleservice.assigned_module.includes(module_id);
      let parent_module:any = this.globalservice.getMultiData(this.commonservice.data.modules,'origin_id', module_id);
      // console.log('parent',parent_module);
      let ret = false;
      if(parent_module.length > 0){
        for(let p of parent_module){
            if(p.parent==false){
              // console.log('child',p);
              if(!this.moduleservice.assigned_module.includes(p.id)){
                ret = true;
              }
            }
        }

      }

      return ret;

  }
  accessValue2(module_id: any = 0) {
    return this.moduleservice.assigned_module.includes(module_id);
  }

}
