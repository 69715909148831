import {  APP_INITIALIZER, NgModule  } from '@angular/core';

import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration } from '@angular/common/http';



import { AssignmoduleComponent } from 'src/app/general/assignmodule/assignmodule.component';
import { ProjectassignComponent } from 'src/app/general/projectassign/projectassign.component';







import {TableModule} from 'primeng/table';
import {CalendarModule} from 'primeng/calendar';
import {DialogModule} from 'primeng/dialog';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {ToastModule} from 'primeng/toast';
import {RippleModule} from 'primeng/ripple';
import {OverlayPanelModule } from 'primeng/overlaypanel';
import {TieredMenuModule } from 'primeng/tieredmenu';
import {StepsModule } from 'primeng/steps';
import {MultiSelectModule} from 'primeng/multiselect';
import {DividerModule} from 'primeng/divider';
import {ContextMenuModule} from 'primeng/contextmenu';
import {CardModule} from 'primeng/card';
import {TabMenuModule} from 'primeng/tabmenu';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputSwitchModule} from 'primeng/inputswitch';
import {SidebarModule} from 'primeng/sidebar';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {TooltipModule} from 'primeng/tooltip';
import {ProgressBarModule} from 'primeng/progressbar';
import {BadgeModule} from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import {TabViewModule} from 'primeng/tabview';
import {ListboxModule} from 'primeng/listbox';
import {PaginatorModule} from 'primeng/paginator';
import {ChartModule} from 'primeng/chart';
import {InputNumberModule} from 'primeng/inputnumber';
import {NgxDocViewerModule} from 'ngx-doc-viewer'
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {SkeletonModule} from 'primeng/skeleton';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ColorPickerModule} from 'primeng/colorpicker';
import { UserDropdownWizardComponent } from 'src/app/main/shared/user-dropdown-wizard/user-dropdown-wizard.component';
import { UploadAttachmentComponent } from 'src/app/main/shared/upload-attachment/upload-attachment.component';
import { SeeMoreComponent } from 'src/app/main/shared/see-more/see-more.component';
import { StandardTableComponent } from 'src/app/main/shared/standard-table/standard-table.component';
import { ChatComponent } from 'src/app/tools/chat/chat.component';



import { FormWizardComponent } from 'src/app//main/shared/form-wizard/form-wizard.component';
import { AuthInterceptor } from 'src/app/auth/auth.interceptor';
import { AssignUserComponent } from 'src/app/general/assign-user/assign-user.component';
import { AssignReferenceComponent } from 'src/app/general/assign-reference/assign-reference.component';
import { CustomtableComponent } from './table/customtable/customtable.component';
import { CustomformComponent } from './form/customform/customform.component';


import { CustomGrouptableComponent } from './table/grouptable/grouptable.component';

// import { CngHtmlCompilerModule } from '@codehint-ng/html-compiler';


import { ModuleAudittrailComponent } from 'src/app/general/module-audittrail/module-audittrail.component';
import { ModuleCommentsComponent } from 'src/app/general/module-comments/module-comments.component';
import { Referencev2Component } from 'src/app/general/referencev2/referencev2.component';
import { UnderDevelopmentComponent } from './page/under-development/under-development.component';
import { GeneralkanbanComponent } from './kanban/general/generalkanban.component';
import {DndModule} from 'ngx-drag-drop';
import { ConfigService } from 'src/app/services/app-config/config.service';





const appConfig = (config: ConfigService) => {
    return()=>{
      return config.loadConfig();
    }
  }




@NgModule({ exports: [
        AssignmoduleComponent,
        ProjectassignComponent,
        UserDropdownWizardComponent,
        FormWizardComponent,
        UploadAttachmentComponent,
        SeeMoreComponent,
        StandardTableComponent,
        AssignUserComponent,
        AssignReferenceComponent,
        Referencev2Component,
        CustomtableComponent,
        CustomformComponent,
        CustomGrouptableComponent,
        GeneralkanbanComponent,
        ChatComponent,
        ModuleAudittrailComponent,
        ModuleCommentsComponent,
        UnderDevelopmentComponent
    ], imports: [DndModule,
        CommonModule,
        TableModule,
        CalendarModule,
        DialogModule,
        DynamicDialogModule,
        ToastModule,
        RippleModule,
        ReactiveFormsModule,
        FormsModule,
        OverlayPanelModule,
        TieredMenuModule,
        StepsModule,
        MultiSelectModule,
        DividerModule,
        ContextMenuModule,
        CardModule,
        TabMenuModule,
        ButtonModule,
        InputTextModule,
        CheckboxModule,
        RadioButtonModule,
        DropdownModule,
        InputTextareaModule,
        InputSwitchModule,
        SidebarModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        TooltipModule,
        ProgressBarModule,
        BadgeModule,
        BreadcrumbModule,
        TabViewModule,
        ListboxModule,
        PaginatorModule,
        ChartModule,
        InputNumberModule,
        NgxDocViewerModule,
        SelectButtonModule,
        ToggleButtonModule,
        ProgressSpinnerModule,
        AvatarModule,
        AvatarGroupModule,
        SkeletonModule,
        MessagesModule,
        MessageModule,
        ColorPickerModule,
        AssignmoduleComponent,
        ProjectassignComponent,
        UserDropdownWizardComponent,
        FormWizardComponent,
        UploadAttachmentComponent,
        SeeMoreComponent,
        StandardTableComponent,
        ChatComponent,
        AssignUserComponent,
        CustomtableComponent,
        CustomformComponent,
        CustomGrouptableComponent,
        GeneralkanbanComponent,
        AssignReferenceComponent,
        Referencev2Component,
        ModuleAudittrailComponent,
        ModuleCommentsComponent,
        UnderDevelopmentComponent], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appConfig,
            multi: true,
            deps: [ConfigService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi(), withXsrfConfiguration({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-XSRF-TOKEN'
        }))
    ] })
  export class SharedModule { }


