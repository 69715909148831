<!-- form wizard works!
{{componentData | json}} -->
<form id="form" [formGroup]="formGroup" class="form-container" [ngClass]="{'loading-form': service.loader}">

    <div class="loader" *ngIf="service.loader">
        <div class="lds-loader" >
            <div></div><div></div>
            <span>Loading...</span>
        </div>
    </div>

    <div class="grid mt-3 form-content row">

        <ng-container *ngFor="let field of formFields">
            <div class="field {{field.Col}} " [hidden]="field.Type=='image' || field.Type=='images' || field.Type=='hidden' || field.hidden_form" [ngClass]="{'has-error': service.show_errors && service.error_data[field.Id]}"  *ngIf="field.visible != false">
                <div [ngSwitch]="field.Type">
                    <h4 class="mb-0 form-label">{{field.Label}} <span *ngIf = "field.Required" class="asterisk_required">*</span></h4>
                    <input *ngSwitchCase="'input'" type="text" pInputText class="inputfield w-full"
                        [formControlName]="field.Id" [required]="field.Required"
                        >

                    <p-calendar 
                        [formControlName]="field.Id" 
                        *ngSwitchCase="'date'" 
                        autoWidth="false" 
                        appendTo="body" 
                        [readonlyInput]="true"
                        [required]="field.Required"
                        [showButtonBar]="true"
                        [style]="{'width':'100%'}"
                        (onSelect)="dropdownOnChange()"
                        ></p-calendar>

                        
                    <textarea pInputTextarea *ngSwitchCase="'textarea'" [formControlName]="field.Id" rows="5"
                        class="inputfield w-full"></textarea>

                        
                    <p-dropdown *ngSwitchCase="'select'" [options]="getDropdown(field.Value)" [virtualScroll]="true"
                        [itemSize]="30" placeholder="{{field.Label}}" [filter]="true" filterBy="name"
                        [appendTo]="append_to"  optionLabel="name" [showClear]="false" [formControlName]="field.Id" [required]="field.Required"
                        autoWidth="false" optionValue="code" [style]="{'width':'100%'}"
                        [showClear]="field.showClear"
                        >
                    </p-dropdown>

                    <p-dropdown *ngSwitchCase="'select2'" 
                        [options]="service.getOptions(field)" [virtualScroll]="true"
                        [itemSize]="30" placeholder="{{field.Label}}" [filter]="true" filterBy="name" 
                        [appendTo]="append_to" 
                        [optionLabel]="(field.OptionLabel) ? field.OptionLabel : '' " 
                        [optionValue]="(field.OptionValue) ? field.OptionValue : '' "
                        [formControlName]="field.Id" 
                        [required]="field.Required"
                        autoWidth="false"  
                        (onChange)="dropdownOnChange()"
                        
                        [showClear]="field.showClear"
                        [style]="{'width':'100%'}">
                    </p-dropdown>

                    <p-dropdown *ngSwitchCase="'normal-select'"
                    [options]="field.Options" [virtualScroll]="true"
                    [itemSize]="30" placeholder="{{field.Label}}" [filter]="true" filterBy="value"
                    [appendTo]="append_to"
                    optionDisabled="disabled"
                    [optionLabel]="'value'"
                    [optionValue]="'value'"
                    [formControlName]="field.Id" 
                    (onChange)="dropdownOnChange()"
                    [required]="field.Required"
                    autoWidth="false"

                    [showClear]="field.showClear"
                    [style]="{'width':'100%'}"
                    
                    >
                </p-dropdown> 


                    <p-multiSelect [options]="getDropdown(field.Value)" *ngSwitchCase="'multiselect'"
                    
                        placeholder="{{field.Label}}" [formControlName]="field.Id" optionLabel="name" optionValue="code"
                        [virtualScroll]="true" [itemSize]="30" autoWidth="false" 
                        [appendTo]="append_to"  display="chip" [required]="field.Required"
                        [style]="{'width':'100%'}">
                    </p-multiSelect>

                    <p-multiSelect *ngSwitchCase="'multiselect2'" [options]="service.getOptions(field)" [virtualScroll]="true" [itemSize]="30"
                        placeholder="{{field.Label}}" [filter]="true" filterBy="name" [appendTo]="append_to"
                        [optionLabel]="(field.OptionLabel) ? field.OptionLabel : '' "
                        [optionValue]="(field.OptionValue) ? field.OptionValue : '' " [formControlName]="field.Id"
                        [required]="field.Required" autoWidth="false"  [style]="{'width':'100%'}" display="chip">
                    </p-multiSelect>

                    <!-- <p-multiSelect [options]="getDropdown(field.Value)" *ngSwitchCase="'multiselect2'" placeholder="{{field.Label}}"
                        [formControlName]="field.Id" optionLabel="name" optionValue="code" [virtualScroll]="true" [itemSize]="30"
                        autoWidth="false" [appendTo]="append_to" display="chip" [required]="field.Required" [style]="{'width':'100%'}">
                    </p-multiSelect> -->


                    <p-inputSwitch [formControlName]="field.Id" 
                        *ngSwitchCase="'switch'" 
                        styleClass=""
                        class="pl-2 pr-2" 
                        [trueValue]="field.trueValue.value" 
                        [falseValue]="field.falseValue.value">
                    </p-inputSwitch>

                    <p-toggleButton onLabel="Yes" *ngSwitchCase="'switch2'" offLabel="No" offIcon="pi pi-times" iconPos="left" onIcon="pi pi-check" [formControlName]="field.Id"  [style]="{'width': '100%'}"></p-toggleButton>
                    
                    <i95-user-dropdown-wizard
                        *ngSwitchCase="'user'"
                        [userControlName]="field.Id"
                        [userModel]="formGroup.value[field.Id]"
                        (userModelChange)="formGroup.controls[field.Id].setValue($event)"
                        [required]="field.Required"
                        styleClass="form-control col-sm-12"
                        Type="dropdown"
                        [showClear]="field.showClear"
                        [userOnChange]='userOnChange'
                        [appendTo]="append_to" 
                    ></i95-user-dropdown-wizard>

                    <i95-user-dropdown-wizard
                        *ngSwitchCase="'user-multiple'"
                        [userControlName]="field.Id"
                        [userModel]="formGroup.value[field.Id]"
                        (userModelChange)="formGroup.controls[field.Id].setValue($event)"
                        [userOnChange]='userOnChange'
                        [required]="field.Required"
                        styleClass="form-control col-sm-12"
                        Type="multiple"
                        [appendTo]="append_to" 
                    ></i95-user-dropdown-wizard>

                    <azure-input-search
                        *ngSwitchCase="'azure-user'"
                        styleClass="form-control col-sm-12"
                        (selectAzureUserEvent)="formGroup.controls[field.Id].setValue($event)"
                        [model]="formGroup.value[field.Id]"
                        [required]="field.Required"
                    >
                    </azure-input-search>

                    <azure-multiple-selection
                        *ngSwitchCase="'azure-user-multiple'"
                        styleClass="form-control col-sm-12"
                        (selectAzureUserEvent)="formGroup.controls[field.Id].setValue($event)"
                        [model]="formGroup.value[field.Id]"
                        [required]="field.Required"
                        >
                    </azure-multiple-selection>
                    
                    <small *ngIf="service.show_errors && service.error_data[field.Id]">{{service.show_errors && service.error_data[field.Id]}}</small>
                </div>
            </div>
        </ng-container>
    </div>
</form>

<!-- {{formGroup.value | json}} -->