import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { Table, TableModule } from 'primeng/table';
import { ModuleService } from 'src/app/services/general/module.service';
import { ReferenceService } from 'src/app/services/general/reference.service';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { CommonService } from 'src/app/services/common/common.service';
import { GlobalService } from 'src/app/services/global/global.service';

@Component({
    selector: 'app-assign-reference',
    templateUrl: './assign-reference.component.html',
    styleUrls: ['./assign-reference.component.scss'],
    standalone: true,
    imports: [TieredMenuModule, ToastModule, ConfirmDialogModule, OverlayPanelModule, NgFor, FormsModule, InputTextModule, ButtonModule, RippleModule, SharedModule, TableModule, NgIf, DropdownModule, TooltipModule, TitleCasePipe]
})
export class AssignReferenceComponent implements OnInit {

  constructor(
    private confirmationService: ConfirmationService,
    private commonService: CommonService,
    private globalService: GlobalService,
    private referenceService: ReferenceService,
    private moduleService: ModuleService,
  ) { 

 this.onResize();
  }

  @Input() project: number = 0;
  @Input() module: number = 0;
  @ViewChild('referencetable')

  referencetable!: Table;

  
  columns: any;
  references: any;
  assignedReferenceValue: any;
  availableReferenceValue: any;

  saving:boolean = false;

  referenceAction: any = [];
  displayMaximizable: boolean = false;
  loading: boolean = false;
  totalRecords: number = 0;
  table_height: any;
  selectedReference: any;
  showColumn: boolean = false;
  selectedFields: any;

  btnSaving: boolean = false;
  masterlist: any = [];
  tempField: any = [];
  refValue: any = [];
  refData: any = [];
  activeRefId: number = 0;

  refValueAction: number = 0;


  selectedRefValues:any = [];

  ngOnInit(): void {

    this.columns = [];
    this.showColumn = false;
    this.getReferenceList();

    this.commonService.project_change.subscribe((id:any)=>{
        this.project = id;
        this.selectReference(this.activeRefId);
    });
  } 

  dynamicDataFormat(data: any) {
    let newData: any = [];
    this.refValueAction = 1;
    let keys = Object.keys(data);
    keys.forEach(key => {

      let id: any = this.globalService.getData(this.masterlist.fields, 'column_name', key);
      let value = data[key];

      let key_name = key != 'group_id' ? id.id : 'group_id';

      newData[key_name] = value;
    });

    return newData;
  }

  onResize(event?: any) {
    this.table_height = window.innerHeight - 300;
  }

  getReferenceList() {
    this.moduleService.getModuleReferences(this.module).subscribe(
    // this.referenceService.getReferenceList().subscribe(
      res => {
        let response: any = res;
        setTimeout(() => {
          this.references = this.commonService.dropdownFormatter(response, 'reference_name', 'reference_id');
        }, 200)

        // this.totalRecords = response.total;
      },
      err => {
        // console.log(err);
      }
    );
  }

  getReferenceValues(refId: any) {
    this.loading = true;
    this.availableReferenceValue = [];

    if (refId === null) {
      this.loading = false;
      return;
    }

    this.referenceService.getReferenceValues(refId).subscribe(
      res => {
        let response: any = res;
        if (response.length > 0) {
          setTimeout(() => {
            // let testData:any=[];
            // for(var i=1;i<=10000;i++){
            //   testData.push({'Status':'status'+i,'Color':'color'+i,'id':i});
            // }
            // this.availableReferenceValue = testData;
            // this.totalRecords = response.length;
            this.availableReferenceValue = response;
            this.loading = false;
            this.refreshTable();
          }, 200)
          return;
        }
        this.loading = false;
      }, err => {
        // console.log(err);
        this.loading = false;
      }
    )
  }

  refreshTable() {
    let main_table: any = this.referencetable;
    main_table.clear();
    main_table.refreshTable();
  }

  getReferenceColumns(refId: any) {
    if (refId === null) {
      this.masterlist.fields = [];
      this.columns = [];
      return;
    }

    this.referenceService.getReferenceColumns(refId).subscribe(
      res => {
        let response: any = res;
        if (response.length > 0) {
          setTimeout(() => {
            this.masterlist.fields = response;
            this.columns = response;
          }, 200)
        }
      }, err => {
        // console.log(err);
      }
    )
  }

  getAssignedReferenceValues(refId: any) {
    this.loading = true;
    this.assignedReferenceValue = [];

    if (refId === null) {
      this.loading = false;
      return;
    }

    this.referenceService.getAssignedReference(this.project, refId, this.module).subscribe(
      res => {
        let response: any = res;
        if (response.length > 0) {
          setTimeout(() => {
            this.totalRecords = response.length;
            this.assignedReferenceValue = response;
            this.loading = false;
          }, 200)
          return;
        }
        this.loading = false;
      }, err => {
        // console.log(err);
        this.loading = false;
      }
    )
  }

  //dropdown onchange function
  selectReference(refId: any) {
    this.activeRefId = refId;
    this.refData = [];
    this.refValue = [];
    this.getReferenceValues(refId);
    this.getReferenceColumns(refId);
    this.getAssignedReferenceValues(refId);
  }

  showAssignedRefValue(){
    this.selectedRefValues = this.assignedReferenceValue;
  }

  assignRefValue(overlayPanel: any=''){
    let groupIds: any = [];
    this.saving = true;

    this.selectedRefValues.forEach((el:any) => {
      groupIds.push(el.id);
    });
  
    this.referenceService.assignReference(this.project, this.activeRefId, groupIds,this.module).subscribe(
      res=>{
        this.saving = false;
        overlayPanel.hide();
        this.globalService.showSuccess("Reference successfully updated");
        this.getAssignedReferenceValues(this.activeRefId);
      },
      err=>{
        this.globalService.showSuccess("Something went wrong");
        this.saving = false;
      }
    );
  }

  removeAssignedRef(groupId:any){
    this.confirmationService.confirm({
      message: 'Are you sure that you want to deleted this item?',
      accept: async () => {
        this.referenceService.removeAssignedReference(this.project, this.activeRefId, groupId, this.module).subscribe(
          res => {
            if (!res) { this.globalService.showError("Deletion Failed"); return; }
            this.globalService.showSuccess("Data successfully deleted");
            this.getAssignedReferenceValues(this.activeRefId);
          },
          err => {
            console.log(err);
          }
        )
      }
    });
  }
  /* reference value crud */

  saveRefValue(overlayPanel: any = '') {
    this.refData = [];
    let group_id = 0;


    this.refValue.forEach((value: any, key: any) => {
      let data: any = {
        master_list_column_id: key,
        master_list_id: this.selectedReference,
        value_name: value
      };

      if (this.refValueAction == 1) {
        group_id = this.refValue['group_id'];
        data['group_id'] = this.refValue['group_id'];
      }

      this.refData.push(data);
    });

    this.referenceService.saveReferenceValue(this.refData, this.selectedReference, this.refValueAction, group_id).subscribe(
      res => {
        let response: any = res;
        if (!response) { this.globalService.showError("Failed to save value"); return; }
        this.getReferenceValues(this.selectedReference);
        this.refValue = [];
        if (this.refValueAction == 1) {
          overlayPanel.hide();
        }
        this.globalService.showSuccess("Reference saved successfully!");
      }, err => {
        return this.globalService.showError(err);
      }
    )

  }

  addRefValue(refId: any,) {
    this.getReferenceColumns(refId);
    this.refValueAction = 0;
  }
}
