<p-tieredMenu #menu [model]="referenceAction" [popup]="true" appendTo="body">

</p-tieredMenu>

<p-toast></p-toast>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-overlayPanel #refValuePanel appendTo="body" [dismissable]="true">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col mb-3" *ngFor="let field of masterlist.fields">
            <h4 class="mb-1">{{field.column_name | titlecase}}</h4>
            <input id="column" type="text" pInputText [(ngModel)]="refValue[field.id]" />
        </div>
    </div>
    <button pButton pRipple type="button" label="Save" class="p-button-raised w-full mt-3"
        (click)="saveRefValue(refValuePanel)"></button>
</p-overlayPanel>

<p-overlayPanel #opRefAssignment appendTo="body" [dismissable]="true" [style]="{width: '550px'}">
    <ng-template pTemplate>
    <p-table [value]="availableReferenceValue" class="i95-table"
        [rowHover]="true" [loading]="loading"
        scrollHeight="flex" [autoLayout]="true" [resizableColumns]="true"
        [style]="{height: '320px'}" [scrollable]="true" styleClass="p-datatable-responsive-demo mb-6"
        [(contextMenuSelection)]="referenceAction" [contextMenu]="menu" dataKey="id"
        [paginator]="false" responsiveLayout="scroll"
        [(selection)]="selectedRefValues"  >
       
       
        <ng-template pTemplate="header">
            <tr *ngIf="columns.length <= 0">
                <th colspan="2" class="text-center">
                    No records found!
                </th>
            </tr>
            <tr *ngIf="columns.length > 0">
                <th class="text-center table-chckbox" *ngIf="columns.length > 0">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th style="width:150px" class="p-5" *ngFor="let col of columns" pSortableColumn="{{col.column_name}}">{{col.column_name |
                    titlecase}}
                    <p-sortIcon field="{{col.column_name}}"></p-sortIcon>
                </th>
            </tr>
            <tr *ngIf="columns.length > 0">
                <th class="text-center table-chckbox" ></th>
                <th style="width:150px" *ngFor="let col of columns" >
                    <p-columnFilter field="{{col.column_name}}" matchMode="contains" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <input type="text" pInputText [ngModel]="value" (ngModelChange)="filter($event)" class="p-inputtext"
                                placeholder="Search Here">
                        </ng-template>
                    </p-columnFilter>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-ref let-rowIndex="rowIndex">
            <tr  height="40">
                <td class="text-center table-chckbox">
                    <p-tableCheckbox [value]="ref"></p-tableCheckbox> 
                    <!-- <p-checkbox name="group1" value="{{ref.group_id}}" [(ngModel)]="selectedRefValues" inputId="sf"></p-checkbox> -->
                </td>
                <td style="width:150px" *ngFor="let col of columns" title="{{ref[col.column_name]}}">{{ref[col.column_name] | titlecase}} </td>
            </tr>
        </ng-template>
    </p-table>
    <button pButton pRipple type="button" label="Save" class="p-button-raised w-full mt-2" [disabled]="saving"
        (click)="assignRefValue(opRefAssignment)"></button>
    </ng-template>
   
</p-overlayPanel>


<div class="p-d-flex mb-2">
    <div class="grid">
        <div class="col-6">
            <p-dropdown [options]="references" [virtualScroll]="true" [itemSize]="30" placeholder="Reference"
                [filter]="true" styleClass="pull-left" filterBy="name" [showClear]="true" appendTo="body"
                optionLabel="name" [showClear]="false" [(ngModel)]="selectedReference" autoWidth="false"
                optionValue="code" [style]="{'width':'65%'}" (onChange)="selectReference(selectedReference)">
            </p-dropdown>

            <!-- <button pButton pRipple type="button" icon="pi pi-check-square" pTooltip="Assign Reference"
                tooltipPosition="bottom" *ngIf="columns.length > 0" class="p-button-raised p-button-info pull-left ml-3"
                (click)="opRefAssignment.toggle($event);showAssignedRefValue();">
            </button> -->

           
            <button pButton pRipple type="button" icon="pi pi-plus" pTooltip="Add reference value" tooltipPosition="bottom" *ngIf="columns.length > 0"
                class="p-button-raised p-button-secondary pull-left m-3 mb-0 mt-0"
                (click)="refValuePanel.toggle($event);addRefValue(selectedReference)">
            </button>
        </div>

        <div class="col-6" *ngIf="columns.length > 0">
            <button pButton pRipple type="button" icon="pi pi-refresh" pTooltip="Refresh" tooltipPosition="bottom"
                class="p-button-outlined p-button-secondary p-button-light pull-right" (click)="getReferenceValues(selectedReference)">
            </button>
            <button pButton pRipple type="button" icon="pi pi-check-square" *ngIf="selectedReference != null"
                label="Assign reference value" pTooltip="Select reference value to be assign from the selected reference."
                tooltipPosition="bottom" class="p-button-outlined p-button-info pull-right m-3 mb-0 mt-0"
                (click)="opRefAssignment.toggle($event);showAssignedRefValue();">
            </button>
        </div>
    </div>
</div>

<p-table [value]="assignedReferenceValue" responsiveLayout="scroll" [rows]="10" [rowHover]="true" [loading]="loading"
    [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,50, 100, 500, 1000]" [totalRecords]="totalRecords"
    scrollHeight="flex" [lazy]="true" [autoLayout]="true" [resizableColumns]="true"
    [style]="{height: table_height+'px'}" [scrollable]="true" styleClass="p-datatable-responsive-demo"
    [(contextMenuSelection)]="referenceAction" [contextMenu]="menu" dataKey="id" class="i95-table" #referencetable>
    <ng-template pTemplate="caption">
        <div class="table-header">
            List of Assigned Reference Values

        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr *ngIf="columns.length <= 0">
            <th colspan="2" class="text-center">
                No records found!
            </th>
        </tr>
        <tr *ngIf="columns.length > 0">
            <th class="text-center table-chckbox" *ngIf="columns.length > 0">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th style="width:150px" class="p-5" *ngFor="let col of columns" pSortableColumn="{{col.column_name}}">{{col.column_name |
                titlecase}}
                <p-sortIcon field="{{col.column_name}}"></p-sortIcon>
            </th>
            <th class="text-center small-width"> </th>
        </tr>
        <tr *ngIf="columns.length > 0">
            <th class="table-chckbox"></th>
            <th style="width:150px" *ngFor="let col of columns">
                <p-columnFilter field="{{col.column_name}}" matchMode="contains" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <input type="text" pInputText [ngModel]="value" (ngModelChange)="filter($event)" class="p-inputtext"
                            placeholder="Search Here">
                    </ng-template>
                </p-columnFilter>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-value>
        <tr>
            <td class="text-center table-chckbox">
                <p-tableCheckbox [value]="value"></p-tableCheckbox>
            </td>
            <td style="width:150px" *ngFor="let col of columns">{{value[col.column_name] | titlecase}} </td>
            <td class="small-width">
                <button type="button" icon="pi pi-trash" (click)="removeAssignedRef(value['id'])"
                    class="p-button-danger p-ripple p-button p-component p-button-icon-only pull-right">
                    <i class="pi pi-trash"></i>
                </button>
            </td>
        </tr>
    </ng-template>
</p-table>