<!-- <div #hello (click)="appScope.test()">Hello !</div> -->

<!-- <ng-template #temp let-item>
    <div>
      This is my variable <strong>{{ item }}</strong> which is dynmically parsed.
    </div>
  </ng-template>
  
  <ng-container
    *ngTemplateOutlet="temp; context: { $implicit: name }"
  ></ng-container> -->
  

  <!-- <cng-html-compiler [template]="template"
                   [componentClass]="componentClass">
</cng-html-compiler> -->

<!-- currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"  -->



    
    <!--   -->

<!-- <cng-html-compiler 
    [template]="template"
    [componentClass]="componentClass">
</cng-html-compiler> -->



<!-- general filter -->

<!-- <cng-html-compiler [template]="template"
    [componentClass]="appScope">
</cng-html-compiler> -->

<ng-template #rowline let-column>

</ng-template>

<p-contextMenu [model]="context_item" #cm  appendTo="body"></p-contextMenu>



<p-dialog header="Comments" [(visible)]="open_comments" [modal]="true" [style]="{ width: 'auto' }" [draggable]="false" [resizable]="false">
 
</p-dialog>

<p-overlayPanel #op [style]="{'width': 'auto'}"  [showCloseIcon]="true" styleClass="remove-padding" appendTo="body">
  
    <ng-template pTemplate="content">
        <div class="comment_card">
            <div #comments class="comments" >

              <div class="comment-container" *ngFor="let col of parse_comments[selected_context.col.Id]">
                <div class="user">
                  <div class="user-pic">
                    <i class="pi pi-user"></i>
                  </div>
                  <div class="user-info">
                    <span> {{commonservice.userName(col.user)}}</span>
                    <p>{{dateFormatTZ(col.datetime, 'MMMM Do YYYY, hh:mm a')}}</p>
                  </div>
                </div>
                <pre class="comment-content">
                 {{col.comment}}
                </pre>
              </div>

            </div>
            
          
            <div class="text-box">
              <div class="box-container">
                <textarea placeholder="Add Comment here.." 
                  [(ngModel)]="comment_content"
                ></textarea>
                <p-button icon="pi pi-send" pTooltip="Send" tooltipPosition="right" [disabled]="!comment_content" styleClass="p-button-rounded" (click)="addComment()"></p-button>
          
                <div>
                </div>
              </div>
            </div>
          </div>
    </ng-template>

</p-overlayPanel>

<p-overlayPanel #cop [style]="{'width': 'auto'}"  styleClass="remove-padding" appendTo="body">
  
    <ng-template pTemplate="content">
       
    </ng-template>

</p-overlayPanel>

<!-- Result output template -->
<ng-template #columnresult let-column>
    
   <ng-container *ngIf="!column.col.cellTemplate" #cell>
       <ng-container [ngSwitch]="column.col.Type">
            <ng-container *ngSwitchCase="'user'" >
                <span pTooltip="{{commonservice.userName(column.data_list[column.col.Id])}}" tooltipPosition="top" >
                    {{commonservice.userName(column.data_list[column.col.Id])}}
                </span>

            </ng-container>
            <ng-container *ngSwitchCase="'date'">
                <span pTooltip="{{dateFormat(column.data_list[column.col.Id],column.col)}}" tooltipPosition="top" >
                    {{dateFormat(column.data_list[column.col.Id],column.col)}}
                </span>
            </ng-container>
            <ng-container *ngSwitchCase="'switch'">
                <p-inputSwitch
                    [ngModel]="switchValue(column.data_list[column.col.Id])"
                    (click)="switchChanged(column.col, column.data_list, column.ri)"
                    *ngSwitchCase="'switch'"
                    styleClass="mt-1"
                    [disabled]="!column.col.enableCellEdit || !allowEdit"
                    [readonly]="true"
                    [trueValue]="column.col.trueValue.value"
                    [falseValue]="column.col.falseValue.value">
                </p-inputSwitch>
       
            </ng-container>

            <ng-container *ngSwitchCase="'textarea'" >
                <pre pTooltip="{{column.data_list[column.col.Id]}}" tooltipPosition="top" >{{column.data_list[column.col.Id]}}</pre>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <span pTooltip="{{column.data_list[column.col.Id]}}" tooltipPosition="top">
                    {{column.data_list[column.col.Id]}}
                </span>
                
            </ng-container>
        </ng-container>
   </ng-container>

   <!-- <ng-container *ngIf="column.col.cellTemplate">
        <span pTooltip="{{ column.col.hideToolTip==true ? '' : column.data_list[column.col.Id]}}" tooltipPosition="top">
            <cng-html-compiler 
                [template]="column.col.cellTemplate"
                [componentClass]="{
                    appScope: appScope,
                    COL_FIELD: column.data_list[column.col.Id],
                    rowData: column.data_list,
                    rowColumn: column.col,
                    rowIndex: column.ri
                }">
            </cng-html-compiler>
        </span>
          
   </ng-container> -->
</ng-template>



<!-- EDIT Row Template -->
<ng-template #columnedit let-column>

   
   <ng-container [ngSwitch]="column.col.Type">

    
            <i95-user-dropdown-wizard
                *ngSwitchCase="'user'"
                [userControlName]="column.col.Id"
                [showClear]="column.col.showClear"
                [userModel]="general_model"
                (userModelChange)="general_model = $event"
                Type="dropdown"
                appendTo="body">
            </i95-user-dropdown-wizard>

            <p-dropdown 
                *ngSwitchCase="'select2'"
                [options]="getOptions(column.col)"
                [virtualScroll]="true"
                [itemSize]="30"
                placeholder="{{column.col.Label}}"
                [filter]="true"
                filterBy="name"
                appendTo="body"
                [optionLabel]="(column.col.OptionLabel) ? column.col.OptionLabel : '' "
                [optionValue]="(column.col.OptionValue) ? column.col.OptionValue : '' "
                [(ngModel)]="general_model"
                autoWidth="false"
                [showClear]="column.col.showClear"
                [style]="{'width':'100%'}">
            </p-dropdown>

            <p-calendar
                *ngSwitchCase="'date'"
                autoWidth="false"
                appendTo="body"
                [(ngModel)]="general_model"
                [readonlyInput]="true"
                [showButtonBar]="true"
                [style]="{'width':'100%'}">
            </p-calendar>

            <p-inputNumber 
                *ngSwitchCase="'number'"
                [(ngModel)]="general_model" 
                [allowEmpty]="true" 
                [useGrouping]="false" >
            </p-inputNumber>

            <textarea 
                *ngSwitchCase="'textarea'"
                pInputTextarea 
                [(ngModel)]="general_model">
            </textarea>

            <input 
                *ngSwitchCase="'input'"
                pInputText 
                type="text"
                [(ngModel)]="general_model"/>


   </ng-container>

</ng-template>



<div *ngIf="general_filter.length > 0" class="general_filter">
    
    <div *ngIf="general_search.length > 0" class="filter-search filter-col">
        <input type="text" pInputText placeholder="Search here..." [(ngModel)]="filter.search" class="w-100" (keyup)="filter.changeSearch($event)"/>
        <i class="pi pi-times filter-remove-btn" *ngIf="filter.search" (click)="filter.search='';filter.executeSearch('')"></i>
    </div>

    <ng-container *ngFor="let col of general_filter" >

        <ng-container [ngSwitch]="col.Type">




            <div  class="filter-col" >


                <ng-container *ngSwitchCase="'select2'">

                    <p-dropdown
                        [options]="col.inGeneralFilter.options"
                        [virtualScroll]="true"
                        [itemSize]="30"
                        [filter]="true"
                        appendTo="body"
                        [placeholder]="col.Label"
                        [showClear]="true"
                        [(ngModel)]="filter.value[col.Id]"
                        (onChange)="filter.change(col, $event.value)"
                        [style]="{'width':'100%'}">
                </p-dropdown>

                </ng-container>
    

                <ng-container *ngSwitchCase="'switch'">
                            <p-dropdown
                                [options]="[{label:col.trueValue.label, value: col.trueValue.value}, {label:col.falseValue.label, value: col.falseValue.value}]"
                                [virtualScroll]="true"
                                [itemSize]="30"
                                [filter]="true"
                                filterBy="name"
                                appendTo="body"
                                [placeholder]="col.Label"
                                [showClear]="true"
                                [(ngModel)]="filter.value[col.Id]"
                                (onChange)="filter.change(col, $event.value)"
                                [style]="{'width':'100%'}">
                            </p-dropdown>
                </ng-container>

                <ng-container *ngSwitchCase="'user'">
                    
                    <i95-user-dropdown-wizard
                            [userControlName]="col.Id"
                            [showClear]="true"
                            [allowed_users]="col.inGeneralFilter.options"
                            [userModel]="filter.value[col.Id]"
                            [placeholder]="col.Label"
                            Type="dropdown"
                            (userModelChange)="filter.change(col, $event)"
                            appendTo="body">
                    </i95-user-dropdown-wizard>

                </ng-container>

                
                <ng-container *ngSwitchCase="'date'">
                    <p-calendar
                        autoWidth="false"
                        appendTo="body"
                        [(ngModel)]="filter.value[col.Id]"
                        [readonlyInput]="true"
                        [showButtonBar]="true"
                        [placeholder]="col.Label"
                        (onSelect)="filter.change(col, filter.value[col.Id])"
                        (onClearClick)="filter.change(col, '')"
                        [style]="{'width':'100%'}">

                        <ng-template pTemplate="date" let-date>
                            <span [ngClass]="{'in_date': filter.inDate(col, date)}">{{date.day}}</span>
                        </ng-template>

                    </p-calendar>
                    <i class="pi pi-times filter-remove-btn" *ngIf="filter.value[col.Id]" (click)="filter.change(col, '')"></i>

        </ng-container>


            </div>
            <!-- <ng-container *ngSwitchCase="'switch'">
                <div *ngIf="general_search.length > 0" class="filter-col" >
                    <input type="text" pInputText [placeholder]="col.Label" class="w-100"/>
                </div>
            </ng-container> -->


        </ng-container>

        
        
    </ng-container>





    <div class="filter-btns">
        <button pButton type="button" label="Clear" class="p-button-sm p-button-danger p-button-rounded" (click)="filter.clear()"></button>
        <button pButton type="button" icon="fa fa-refresh" class="p-button-sm p-button-success p-button-rounded ml-1" (click)="filter.refresh()"></button>
    </div>


</div>






<div style="float:left; width: 100%" >


    <p-table 
        #i95table
        [value]="value"
        [scrollHeight]="(table_height-50)+'px'"
        [style]="{height: table_height+'px'}"
        [styleClass]="styleClass +' i95-table'"
        [resizableColumns]="false"
        (onEditInit)="onEditInit($event)"
        (onEditComplete)="onEditComplete($event)"
        [rowHover]="true"
        [loading]="loading"
        [totalRecords]="totalRecords"
        [paginator]="true"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} to {last} of {totalRecords} entries"
        [rows]="rows"
        [rowsPerPageOptions]="[10,50, 100, 500, 1000]"
        (onLazyLoad)="getList($event, i95table)"
        [lazy]="true"
        responsiveLayout="stack"
        [scrollable]="(!commonservice.is_mobile)"
        [autoLayout]="true"
        [responsive]="true"
        scrollDirection="horizontal"
        [sortField]="sortfield"
        [sortOrder]="sortorder"
        dataKey="id"
        (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowSelect($event)"
        [dataKey]="groupBy" 
        rowGroupMode="subheader" 

        
        [(selection)]="selected_item"
        
        [groupRowsBy]="groupBy" 
        (onRowExpand)="onRowExpand()" 
        (onRowCollapse)="onRowCollapse()" 
        [expandedRowKeys]="expandedRows"
        
        [tableStyle]="{'min-width': '70rem'}">

    <ng-template pTemplate="header">
        <tr class="head-label" >

            <th class="table-chckbox" alignFrozen="left"  pFrozenColumn [frozen]="true" *ngIf="selection">
                <button type="button" (click)="expandAll()" pButton pRipple class="p-button-text p-button-rounded p-button-plain mr-2 p-button-sm" [icon]="isExpanded ? 'pi pi-minus' : 'pi pi-plus'"></button>
            
            </th>

            <th class="table-chckbox" alignFrozen="left"  pFrozenColumn [frozen]="true" *ngIf="selection">
                <p-checkbox
                [binary]="true"
                [(ngModel)]="header_checked"
                (ngModelChange)="checkAll($event)"
                ></p-checkbox>
            </th>

    
    
    
            <ng-container *ngFor="let col of fields" >
                <th pSortableColumn="{{col.Id}}"
                    *ngIf="allowShowField(col.Id) && !col.hideField"
                    [style]="columnStyle(col)"
                    class="{{ (col.headerCellClass) ? col.headerCellClass : '' }}"
                    pFrozenColumn
                    [alignFrozen]="(col.alignFrozen) ? col.alignFrozen : 'right'"  
                    [frozen]="col.frozen"
                    >
                    {{col.Label}}
                </th>
            </ng-container>
    
            <!-- <th>Action</th> -->
            <th class="small-width text-center" *ngIf="showAction" alignFrozen="right" pFrozenColumn [frozen]="true">
                <span class="p-frozen-col-inner">Action</span>
            </th>
    
        </tr>
    
    
        <tr *ngIf="allowFilter"  class="head-sort">
    
    
            <th class="table-chckbox" alignFrozen="left"  pFrozenColumn [frozen]="true" *ngIf="selection">
                <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
            </th>
           <th class="table-chckbox" alignFrozen="left"  pFrozenColumn [frozen]="true" *ngIf="selection">
                <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
            </th>
    
    
    
    
       <!-- TABLE FILTER -->
            <ng-container *ngFor="let col of fields" >
                <th
                pFrozenColumn
                [alignFrozen]="(col.alignFrozen) ? col.alignFrozen : 'right'"  
                [frozen]="col.frozen"
                
                *ngIf="allowShowField(col.Id)  && !col.hideField"
                class="{{ (col.headerCellClass) ? col.headerCellClass : '' }}"
                [style]="columnStyle(col)"
                [ngSwitch]="col.Type">
    
                    <ng-container  *ngIf="!col.removeFilter">
                        <p-columnFilter [field]="col.Id" matchMode="equals" [showMenu]="false" *ngSwitchCase="'user'">
                                            <ng-template pTemplate="filter">
    
                                                <i95-user-dropdown-wizard
                                                    [userControlName]="col.Id"
                                                    [showClear]="true"
                                                    [userModel]="event.filters[col.Id].value"
                                                    Type="dropdown"
                                                    (userModelChange)="filterChange(col, $event)"
                                                    appendTo="body">
                                                </i95-user-dropdown-wizard>
    
    
                                            </ng-template>
                        </p-columnFilter>
    
                        <p-columnFilter [field]="col.Id" matchMode="equals" [showMenu]="false" *ngSwitchCase="'select2'">
                                            <ng-template pTemplate="filter">
    
                                                <p-dropdown
                                                    [options]="getOptions(col)"
                                                    [virtualScroll]="true"
                                                    [itemSize]="30"
                                                    placeholder="{{col.Label}}"
                                                    [filter]="true"
                                                    filterBy="name"
                                                    appendTo="body"
                                                    [optionLabel]="(col.OptionLabel) ? col.OptionLabel : '' "
                                                    [optionValue]="(col.OptionValue) ? col.OptionValue : '' "
                                                    [(ngModel)]="event.filters[col.Id].value"
                                                    autoWidth="false"
                                                    (onChange)="filterChange(col, event.filters[col.Id].value)"
                                                    [showClear]="true"
                                                    [style]="{'width':'100%'}">
                                            </p-dropdown>
    
    
                                            </ng-template>
                        </p-columnFilter>
    
                        <p-columnFilter [field]="col.Id" matchMode="equals" [showMenu]="false" *ngSwitchCase="'switch'">
                                            <ng-template pTemplate="filter">
    
                                                <p-dropdown
                                                    [options]="[{label:'All', value: null}, {label:col.trueValue.label, value: col.trueValue.value}, {label:col.falseValue.label, value: col.falseValue.value}]"
                                                    [virtualScroll]="true"
                                                    [itemSize]="30"
                                                    [filter]="true"
                                                    filterBy="name"
                                                    appendTo="body"
                                                    [(ngModel)]="event.filters[col.Id].value"
                                                    autoWidth="false"
                                                    (onChange)="filterChange(col, event.filters[col.Id].value)"
                                                    [showClear]="false"
                                                    [style]="{'width':'100%'}">
                                            </p-dropdown>
    
    
                                            </ng-template>
                        </p-columnFilter>
    
                        <p-columnFilter [field]="col.Id" matchMode="equals" [showMenu]="false" *ngSwitchCase="'date'" >
                                            <ng-template pTemplate="filter">
    
                                                <p-calendar
                                                    autoWidth="false"
                                                    appendTo="body"
                                                    [(ngModel)]="custom_filter[col.Id]"
                                                    [readonlyInput]="true"
                                                    [showButtonBar]="true"
                                                    (onSelect)="filterChange(col, custom_filter[col.Id])"
                                                    (onClearClick)="filterChange(col, '')"
                                                    [style]="{'width':'100%'}">
                                                </p-calendar>
    
    
                                            </ng-template>
                        </p-columnFilter>
    
    
                        <p-columnFilter matchMode="contains" type="text" [field]="col.Id" [showMenu]="false" *ngSwitchDefault></p-columnFilter>
                    </ng-container>
    
                </th>
            </ng-container>
    
            <th class="small-width text-center p-fc-right" *ngIf="showAction" alignFrozen="right" pFrozenColumn [frozen]="true"></th>
    
        </tr>
    
    
    </ng-template>

    <ng-template pTemplate="groupheader" let-data_list let-ri="rowIndex" let-expanded="expanded">



        <tr *ngIf="show" [ngClass]="rowClass({entity:data_list, rowIndex: ri, col: fields})" >
   
            <td class="table-chckbox" alignFrozen="left"  pFrozenColumn [frozen]="true" *ngIf="selection && !commonservice.is_mobile">
                <button type="button" pButton pRipple [pRowToggler]="data_list" class="p-button-text p-button-rounded p-button-plain mr-2 p-button-sm" [icon]="expanded ? 'pi pi-minus' : 'pi pi-plus'"></button>
            </td>

            <td class="table-chckbox" alignFrozen="left"  pFrozenColumn [frozen]="true" *ngIf="selection && !commonservice.is_mobile">
                <label class="cbx-container" (click)="selectedAllHead(data_list)">
                    <input  type="checkbox" [checked]="selectedHeadItm(data_list)" disabled >
                    <div class="checkmark"></div>
                </label>

            </td>       
   
        
            <ng-container *ngFor="let col of fields;"   >
                <td
                    pFrozenColumn
                    [alignFrozen]="(col.alignFrozen) ? col.alignFrozen : 'right'"  
                    [frozen]="col.frozen"
                    [style]="columnStyle(col)"
                    class="{{ (col.cellClass) ? col.cellClass : '' }}"
                    pEditableColumn 
                    *ngIf="allowShowField(col.Id) && !col.hideField"
                    [ngClass]="{'is_not_mobile_col': !col.is_mobile, 'd-block': commonservice.is_mobile, 'td-content-body': (col.cellTemplate && !col.frozen), 'have-comment': checkComment(col.Id, data_list.comment_data)}"
                    [ngSwitch]="col.Type"
                    [pEditableColumn]="data_list"
                    [pEditableColumnField]="col"
                    (contextmenu)="openContext(op, $event)"
                    [pContextMenuRow]="{col:col, data: data_list, index: ri}"
                    [pEditableColumnRowIndex]="ri">
    
                    <ng-container *ngIf="col.showInHeader">
                        <span class="p-column-title">{{col.Label}}</span>
                        <span class="p-column-value font-bold pointer" [pRowToggler]="data_list">
                            
                           
                            <ng-container>
                                    <ng-template 
                                        *ngTemplateOutlet="columnresult; context: {$implicit: {data_list: data_list, col: col, ri:ri}}" >
                                        <td>hello!</td>
                                    </ng-template>
                            </ng-container>
                        
                        </span>
                    </ng-container>

                </td>
    
            </ng-container>
    
            <td class="small-width text-center p-fc-right" *ngIf="showAction" alignFrozen="right"  pFrozenColumn [frozen]="true">
                <span class="p-frozen-col-inner">
                    <button type="button" icon="pi pi-check" class="p-button-rounded p-button-outlined p-ripple p-button p-component p-button-icon-only"  (click)="openMenu(data_list, $event)"  >
                        <i class="fa  fa-ellipsis-v"></i>
                        <span aria-hidden="true" class="p-button-label">&nbsp;</span>
                    </button>
    
               </span>
            </td>
        </tr>



    </ng-template>

    <ng-template pTemplate="rowexpansion" let-data_list let-ri="rowIndex"  >


        
        
        <tr *ngIf="show" [ngClass]="rowClass({entity:data_list, rowIndex: ri, col: fields})" >
    
            <td class="table-chckbox" alignFrozen="left"  pFrozenColumn [frozen]="true" *ngIf="selection && !commonservice.is_mobile">
            
            </td>

            <td class="table-chckbox" alignFrozen="left"  pFrozenColumn [frozen]="true" *ngIf="selection && !commonservice.is_mobile">
                <!-- <p-checkbox (click)="selectItm(data_list)" [checked]="selectedItm(data_list)"></p-checkbox> -->

                <label class="cbx-container" (click)="selectItm(data_list)">
                    <input  
                        type="checkbox" 
                        [checked]="selectedItm(data_list)" disabled >
                    <div class="checkmark"></div>
                </label>

                <!-- <p-checkbox 
                    [value]="data_list" 
                    [(ngModel)]="selected_item"
                >
              </p-checkbox> -->
            </td>


        
            <ng-container *ngFor="let col of fields;"   >
                <td
                    pFrozenColumn
                    [alignFrozen]="(col.alignFrozen) ? col.alignFrozen : 'right'"  
                    [frozen]="col.frozen"
                    [style]="columnStyle(col)"
                    class="{{ (col.cellClass) ? col.cellClass : '' }}"
                    pEditableColumn 
                    *ngIf="allowShowField(col.Id) && !col.hideField"
                    [ngClass]="{'is_not_mobile_col': !col.is_mobile, 'd-block': commonservice.is_mobile, 'td-content-body': (col.cellTemplate && !col.frozen), 'have-comment': checkComment(col.Id, data_list.comment_data)}"
                    [ngSwitch]="col.Type"
                    [pEditableColumn]="data_list"
                    [pEditableColumnField]="col"
                    (contextmenu)="openContext(op, $event)"
                    [pContextMenuRow]="{col:col, data: data_list, index: ri}"
                    [pEditableColumnRowIndex]="ri">
    
                    <span class="p-column-title">{{col.Label}}</span>
                    <span class="p-column-value">
                        
                        
                        <ng-container>
                            <p-cellEditor *ngIf="col.enableCellEdit && allowEdit && col.Type!='switch'" >

                                <ng-template pTemplate="input" >
                                    <ng-template 
                                        *ngTemplateOutlet="columnedit; context: {$implicit: {data_list: data_list, col: col, ri:ri}}" >
                                    </ng-template>
                                </ng-template>

                                <ng-template pTemplate="output">
                                    <ng-template 
                                        *ngTemplateOutlet="columnresult; context: {$implicit: {data_list: data_list, col: col, ri:ri}}" >                                        
                                        <td>hello!</td>
                                    </ng-template>
                                </ng-template>

                            </p-cellEditor>

                            <ng-container *ngIf="!col.enableCellEdit || !allowEdit || col.Type=='switch'">
                                <ng-template 
                                    *ngTemplateOutlet="columnresult; context: {$implicit: {data_list: data_list, col: col, ri:ri}}" >
                                    <td>hello!</td>
                                </ng-template>
                            </ng-container>
                        </ng-container>
                    
                    </span>
                </td>
    
            </ng-container>
    
            <td class="small-width text-center p-fc-right" *ngIf="showAction" alignFrozen="right"  pFrozenColumn [frozen]="true">
                <span class="p-frozen-col-inner">
                    <button type="button" icon="pi pi-check" class="p-button-rounded p-button-outlined p-ripple p-button p-component p-button-icon-only"  (click)="openMenu(data_list, $event)"  >
                        <i class="fa  fa-ellipsis-v"></i>
                        <span aria-hidden="true" class="p-button-label">&nbsp;</span>
                    </button>
    
               </span>
            </td>
        </tr>
    </ng-template>
</p-table>
</div>

